import React from 'react';
import Image from 'react-bootstrap/Image';
const backendAddress = process.env.GATSBY_COCKPIT_URL;
const token = process.env.GATSBY_COCKPIT_TOKEN;

class DynamicImage extends React.Component {
  render() {
    const { src } = this.props;
    return (
      <Image
        fluid
        style={{
          objectFit: 'cover',
          height: '360px',
          width: '100%',
        }}
        src={`${backendAddress}/pucka/api/cockpit/image?token=${token}&src=${src}&w=960&o=1`}
      />
    );
  }
}

export default DynamicImage;
