/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Header from './header';
import Footer from './footer';
import { Helmet } from 'react-helmet';
import '../styles/layout.scss';
import BottomText from './bottomText';
import favicon from '../images/zatoka-pucka-logo.png';

class Layout extends React.Component {
  state = {
    toggle: false,
  };
  render() {
    const { children, mainPage, noBottomLogo } = this.props;
    const { toggle } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query layoutQuery {
            fileInfo: file(relativePath: { eq: "bg.png" }) {
              childImageSharp {
                fixed(width: 1280, quality: 85) {
                  src
                }
              }
            }
          }
        `}
        render={data => (
          <div
            className={mainPage && 'main-page'}
            style={
              mainPage
                ? {
                    backgroundImage: `url(${
                      data.fileInfo.childImageSharp.fixed.src
                    })`,
                    backgroundPositionY: 'bottom',
                    backgroundPositionX: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                    minHeight: '100vh',
                  }
                : {}
            }
          >
            <Helmet>
              <title>Zatoka Pucka - twoje miejsce we Wrocławiu</title>
              <meta name="theme-color" content="#663399" />
              <link rel="shortcut icon" type="image/png" href={favicon} />
            </Helmet>
            <Header
              mainPage={mainPage}
              clickToggle={() => {
                this.setState(prevState => ({
                  toggle: !prevState.toggle,
                }));
              }}
            />
            <main>{children}</main>
            <Footer
              mainPage={mainPage}
              noBottomLogo={noBottomLogo}
              toggle={toggle}
            />
            {!mainPage && <BottomText />}
          </div>
        )}
      />
    );
  }
}

export default Layout;
