import React from 'react';
import '../styles/main.scss';

const BottomText = ({ children, mainPage }) => (
  <div
    className="container mt-5 bottom-text text-justify"
    style={{
      marginBottom: '200px',
    }}
  >
    Prezentowane wizualizacje, rzuty i treści służą wyłącznie do celów
    prezentacyjnych i nie stanowią oferty w rozumieniu kodeksu cywilnego.
    Podczas realizacji niektóre szczegóły mogą zostać
    zweryfikowane&nbsp;i&nbsp;nieznacznie ulec zmianie. Inwestor zastrzega sobie
    prawo do drobnych zmian w projekcie a także do zmiany materiałów budowlanych
    i wykończeniowych oraz ich dostawców.
  </div>
);

export default BottomText;
