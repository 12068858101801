import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Lightbox from '../components/lightbox';
import DynamicImage from '../components/dynamicImage';
import Parser from 'html-react-parser';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

import '../styles/news.scss';

const backendAddress = process.env.GATSBY_COCKPIT_URL;

class NewsPage extends React.Component {
  state = {
    isOpen: {},
  };

  hideLightbox = id => {
    this.setState(prevState => ({
      isOpen: {
        ...prevState.isOpen,
        [id]: false,
      },
    }));
  };

  render() {
    const { isOpen } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query newsQuery {
            news: cockpit {
              collection(name: "news")
            }
          }
        `}
        render={data => (
          <Layout>
            <div
              className="container mt-5"
              style={{
                marginBottom: ' 50px',
              }}
            >
              {data.news.collection
                .sort((a, b) => {
                  if (a._o > b._o) {
                    return 1;
                  }
                  return -1;
                })
                .map((n, idxx) => {
                  return (
                    <>
                      <div key={n._id} className="mt-5 mb-5">
                        <h2 className="head-underlined">{n.title}</h2>
                        <div className="row mt-3">
                          <div className="col-12">
                            {<p>{Parser(n.description || '')}</p>}
                          </div>
                          {n.linkyt && (
                            <div
                              className="col-12"
                              style={{
                                paddingTop: '56.25%',
                                position: 'relative',
                              }}
                            >
                              <iframe
                                src={n.linkyt.replace('watch?v=', 'embed/')}
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                }}
                              />
                            </div>
                          )}
                          {n.gallery.map((g, idx) => (
                            <div
                              key={`idx-${g.meta.asset}-${idx}`}
                              className="col-12 col-md-6 mt-3"
                              style={{
                                paddingTop: '15px',
                                cursor: 'pointer',
                                height: 'fit-content',
                              }}
                              onClick={() =>
                                this.setState(prevState => ({
                                  isOpen: {
                                    ...prevState.isOpen,
                                    [n._id]: true,
                                  },
                                }))
                              }
                            >
                              {idxx === 0 && idx === 0 && (
                                <Helmet>
                                  <meta property="og:title" content={n.title} />
                                  <meta
                                    property="og:description"
                                    content={n.description.replace(
                                      /<[^>]*>?/gm,
                                      ''
                                    )}
                                  />
                                  <meta
                                    property="og:url"
                                    content="https://zatokapucka.com.pl/news/"
                                  />
                                  <meta property="og:type" content="website" />
                                  <meta
                                    property="og:image"
                                    content={`${backendAddress}${g.path}`}
                                  />
                                </Helmet>
                              )}
                              <DynamicImage
                                src={`${backendAddress}${g.path}`}
                              />
                            </div>
                          ))}
                        </div>
                        <div />
                        <Lightbox
                          gallery={n.gallery}
                          id={n._id}
                          hideLightbox={this.hideLightbox}
                          isOpen={isOpen[n._id]}
                        />
                      </div>
                    </>
                  );
                })}
            </div>
          </Layout>
        )}
      />
    );
  }
}

export default NewsPage;
