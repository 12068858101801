import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const backendAddress = process.env.GATSBY_COCKPIT_URL;
const token = process.env.GATSBY_COCKPIT_TOKEN;

class NewsPage extends React.Component {
  state = {
    photoIndex: 0,
  };

  render() {
    const { photoIndex } = this.state;
    const { id, gallery, hideLightbox, isOpen } = this.props;
    const images = gallery.map(
      g =>
        `${backendAddress}/pucka/api/cockpit/image?token=${token}&src=${backendAddress}${
          g.path
        }&w=1960&o=1`
    );

    if (!isOpen) {
      return null;
    }

    return (
      <Lightbox
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={() => hideLightbox(id)}
        onMovePrevRequest={() =>
          this.setState({
            photoIndex: (photoIndex + images.length - 1) % images.length,
          })
        }
        onMoveNextRequest={() =>
          this.setState({
            photoIndex: (photoIndex + 1) % images.length,
          })
        }
      />
    );
  }
}

export default NewsPage;
