import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'react-bootstrap/Image';
import classNames from 'classnames';
import { Link } from 'gatsby';

import '../styles/footer.scss';

class Footer extends React.Component {
  render() {
    const { mainPage, footerBottom, noBottomLogo, toggle } = this.props;

    if (toggle) {
      return null;
    }

    return (
      <StaticQuery
        query={graphql`
          query footerQuery {
            fileInfo: file(relativePath: { eq: "blue-forest-logo.png" }) {
              childImageSharp {
                fixed(width: 400) {
                  src
                }
              }
            }
            cockpit: cockpit {
              singleton(name: "homepage")
            }
          }
        `}
        render={data => (
          <>
            <footer
              className={classNames(
                { 'footer-top': !mainPage },
                { 'footer-bottom': footerBottom }
              )}
            >
              <div className="footer-content">
                <div className="spread-words">
                  <a href={`mailto:${data.cockpit.singleton.email}`}>
                    email: {data.cockpit.singleton.email}
                  </a>
                  <p className="d-none d-md-inline">|</p>
                  <a href={`tel:${data.cockpit.singleton.phone}`}>
                    telefon: {data.cockpit.singleton.phone}
                  </a>
                  <p className="d-none d-md-inline">|</p>
                  <Link to="/developer">Blue Forest Development</Link>
                </div>
              </div>
              {mainPage && (
                <Link to="/developer">
                  <Image
                    src={data.fileInfo.childImageSharp.fixed.src}
                    id="footer-logo"
                    alt="Blue forest logo"
                    fluid
                  />
                </Link>
              )}
            </footer>
            {!mainPage && !noBottomLogo && (
              <Link to="/developer">
                <Image
                  src={data.fileInfo.childImageSharp.fixed.src}
                  className="bottom-logo"
                  id="footer-logo"
                  alt="Blue forest logo"
                  fluid
                />
              </Link>
            )}
          </>
        )}
      />
    );
  }
}

export default Footer;
