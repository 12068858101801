import { Link } from 'gatsby';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import classNames from 'classnames';

import '../styles/header.scss';

class Header extends React.Component {
  componentDidMount() {
    this.setState({ activePage: window.location.pathname });
    Array.from(
      document.getElementById('header').getElementsByClassName('spread-letters')
    ).forEach(el => {
      const text = el.innerText.split('');
      const newContent = text
        .map(letter =>
          letter === ' ' ? '<span>&nbsp;</span>' : `<span>${letter}</span>`
        )
        .join('');
      el.innerHTML = newContent;
    });
  }

  render() {
    const { mainPage, clickToggle } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query headerQuery {
            fileInfo: file(relativePath: { eq: "zatoka-pucka-logo.png" }) {
              childImageSharp {
                fixed(width: 88) {
                  src
                }
              }
            }
          }
        `}
        render={data => (
          <header
            id="header"
            className={classNames({ 'header-margin-top': !mainPage })}
          >
            <Navbar bg="transparent" expand="md">
              <Navbar.Brand className="p-0 m-0">
                <Link to="/developer">
                  <img
                    src={data.fileInfo.childImageSharp.fixed.src}
                    alt="Zatoka pucka logo"
                    className="brand-logo"
                  />
                </Link>
                <div className="d-md-none d-sm-inline small-brand ml-5">
                  ZATOKA PUCKA
                </div>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className="ml-5"
                  onClick={() => clickToggle()}
                />
              </Navbar.Brand>
              <Navbar.Collapse id="basic-navbar-nav" className="mt-5 mt-md-0">
                <Nav className="d-flex flex-column ml-0 ml-md-5">
                  <div className="d-none d-md-inline nav-top">
                    <h1 id="brand-name" className="spread-letters">
                      ZATOKA PUCKA
                    </h1>
                  </div>
                  <div className="nav-bottom d-flex flex-md-row flex-column spread-words mt-3 text-center">
                    <Link to="/" activeStyle={{ fontWeight: '700' }}>
                      home
                    </Link>
                    <span className="d-none d-md-inline">|</span>
                    <Link to="/location" activeStyle={{ fontWeight: '700' }}>
                      lokalizacja
                    </Link>
                    <span className="d-none d-md-inline">|</span>
                    <Link to="/houses" activeStyle={{ fontWeight: '700' }}>
                      domy
                    </Link>
                    <span className="d-none d-md-inline">|</span>
                    <Link to="/gallery" activeStyle={{ fontWeight: '700' }}>
                      galeria
                    </Link>
                    <span className="d-none d-md-inline">|</span>
                    <Link to="/developer" activeStyle={{ fontWeight: '700' }}>
                      deweloper
                    </Link>
                    <span className="d-none d-md-inline">|</span>
                    <Link to="/news" activeStyle={{ fontWeight: '700' }}>
                      aktualności
                    </Link>
                    <span className="d-none d-md-inline">|</span>
                    <Link to="/contact" activeStyle={{ fontWeight: '700' }}>
                      kontakt
                    </Link>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </header>
        )}
      />
    );
  }
}

export default Header;
